<template>
  <section class="all-videos-list">

    <div class="filters">
      <select v-for="(terms, taxonomy) in taxonomyFilters"
              :id="taxonomy"
              :name="taxonomy"
              class="filter"
              :key="taxonomy"
              @change.prevent="filterBy($event, taxonomy)">
        <option value="" :selected="termFilter === null" disabled>Filter by</option>
        <option v-for="term in terms"
                :value="term.slug"
                :key="term.slug"
        >{{ term.name }}</option>
      </select>
      <button class="filters__clear" @click.prevent="clearFilters()">clear</button>
    </div>

    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <template v-for="(post, index) in filteredPosts" :key="index" style="width:100%;height:100%;">
        <video-player v-if="post.title && post.thumbnail"
            :type="post.type"
            :src="post.url"
            :custom-title="post.title"
            :custom-thumbnail="post.thumbnail"
            :controls="true"
            :modal="true"
            max-width="720px"
        >
          <template v-slot:button>
            <span></span>
          </template>
        </video-player>
        <video-player v-else
            :type="post.type"
            :src="post.url"
            :controls="true"
            :modal="true"
            max-width="720px"
        >
          <template v-slot:button>
            <span></span>
          </template>
        </video-player>
      </template>
    </div>

  </section>
</template>

<script>
import VideoPlayer from "./video/VideoPlayer.vue";

export default {
    components: {
      VideoPlayer,
    },

    props: {
      posts: {
        type: Array,
      },
      taxonomies: {
        type: Array,
      },
    },

    data() {
      return {
        termFilter: null
      };
    },

    computed: {
      filteredPosts() {
        if (this.termFilter === null) {
          return this.posts;
        }
        return this.posts.filter((post) => {
          return this.postHasTerm(post, this.termFilter.term_id);
        });
      },

      taxonomyFilters() {
        let filters = {};

        for (let taxonomy of this.taxonomies) {
          for (let term of taxonomy) {
            if (!filters.hasOwnProperty(term.taxonomy)) {
              filters[term.taxonomy] = [];
            }
            filters[term.taxonomy] = [...filters[term.taxonomy], term];
          }
        }
        return filters;
      }
    },
    methods: {
      filterBy(event, taxonomy) {
        let termIndex = this.taxonomyFilters[taxonomy].findIndex((term) => term.slug === event.target.value);
        this.termFilter = this.taxonomyFilters[taxonomy][termIndex];
      },

      clearFilters() {
        this.termFilter = null;
      },

      postHasTerm(post, termId) {
        if (post.hasOwnProperty('terms') && Array.isArray(post.terms)) {
          return post.terms.map(term => term.term_id).includes(termId);
        }
        return false;
      }
    }
}
</script>