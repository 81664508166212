<template>
    <VideoWrapper :maxWidth="maxWidth">
        <a v-if="showPreview" ref="videoWrapper" class="video-preview" @click="handleClick">
            <template v-if="isVideoFound && !autoplay">
                <video-thumbnail
                    :custom-thumbnail="customThumbnail"
                    :max-width="maxWidth"
                    :show-title="showTitle"
                    :teaser-thumbnail="teaserThumbnail"
                    :teaser-type="teaserType"
                    :thumbnail-quality="thumbnailQuality"
                    :title="title"
                    :type="type"
                    :video-id="videoId"
                >
                </video-thumbnail>

                <div v-show="!clicked" class="video-button-wrapper">
                    <!-- Slot for the button -->
                    <slot name="button">
                        <button>Play</button>
                    </slot>
                </div>

                <div v-show="clicked && !modal" ref="videoLoaderWrapper" class="video-loader-wrapper">
                    <!-- Slot for the loader -->
                    <slot name="loader">
                        <span class="video-loading-ring">
                            <span></span><span></span><span></span>
                        </span>
                    </slot>
                </div>
            </template>

            <template v-else>
                <!-- Static display message -->
                <video-error></video-error>
            </template>
        </a>

        <template v-if="modal">
            <div ref="videoModal" class="video-modal">
                <div class="modal-fade-screen">
                    <div class="modal-inner">
                        <a class="modal-close" @click="closeModal"></a>
                        <div ref="videoWrap" class="theater-wrap"></div>
                    </div>
                </div>
            </div>
        </template>

        <div v-else ref="videoWrap" class="iframe-wrap"></div>
    </VideoWrapper>
</template>

<script>
import VideoWrapper from './VideoWrapper.vue';
import VideoThumbnail from './VideoThumbnail.vue';
import VideoError from './VideoError.vue';

export default {
    name: 'VideoPlayer',
    components: {
        VideoWrapper,
        VideoThumbnail,
        VideoError,
    },
    props: {
        /**
         ******************** Required *******************
         */

        /**
         * Type of video
         */
        type: {
            type: String,
            required: true,
            validator(value) {
                switch (value) {
                    case "youtube":
                    case "vimeo":
                    case "hosted":
                        return true;
                    default:
                        return false;
                }
            }
        },
        src: {
            type: String,
            required: true
        },

        /**
         * ******************* Optional *******************
         */

        autoplay: {
            type: Boolean,
            default: false,
            required: false,
        },
        controls: {
            type: Boolean,
            default: false,
            required: false,
        },
        /**
         * An image to replace the video's thumbnail for the preview
         */
        customThumbnail: {
            type: String,
            default: '',
            required: false,
        },
        /**
         * A title to replace the video's title on the preview
         */
        customTitle: {
            type: String,
            default: '',
            required: false,
        },
        /**
         * The classes that are applied to the iframe
         */
        iframeClass: {
            type: String,
            default: 'iframe',
            required: false,
        },
        loop: {
            type: Boolean,
            default: false,
            required: false,
        },
        maxWidth: {
            type: String,
            default: '560px',
            required: false,
        },
        /**
         * AKA lightbox.
         * This will determine if the video will open up in a popup window.
         */
        modal: {
            type: Boolean,
            default: false,
            required: false,
        },
        mute: {
            type: Boolean,
            default: false,
            required: false,
        },
        /**
         * Whether the video title should be overlaid
         */
        showTitle: {
            type: Boolean,
            default: true,
            required: false
        },
        /**
         * Whether there should be a preview (image or video)
         * If this is set to true, the video will be inserted when you click play
         * If this is set to false, the video will be inserted on page load
         */
        showPreview: {
            type: Boolean,
            default: true,
            required: false,
        },
        /**
         * A video that will replace the preview image with a teaser video.
         * This video will autoplay, while muted, and will not have controls. These properties cannot be altered.
         */
        teaserThumbnail: {
            type: String,
            default: '',
            required: false,
        },
        /**
         * What type of video the teaser thumbnail is.
         */
        teaserType: {
            type: String,
            default: 'default',
            required: false,
        },
        thumbnailQuality: {
            type: String,
            default: 'standard',
            required: false,
            validator(value) {
                switch (value) {
                    case "low":
                    case "medium":
                    case "high":
                    case "standard":
                    case "maxres":
                        return true;
                    default:
                        return false;
                }
            },
        },
    },
    data() {
        return {
            clicked: false,
            iframeEl: null,
            isVideoFound: false,
            videoInfo: null,
        }
    },
    computed: {
        /**
         * Calculates the video's ID by parsing the src passed in
         */
        videoId() {
            switch (this.type) {
                case "youtube":
                    let url = this.src.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/u);
                    /* eslint-disable no-useless-escape */
                    return (url[2] !== undefined) ? url[2].split(/[^\da-z_\-]/iu)[0] : url[0];
                case "vimeo":
                    return new URL(this.src).pathname.split('/').pop();
                case "hosted":
                    return "hosted";
            }
        },
        title() {
            if (this.customTitle) {
                return this.customTitle;
            }
            if (this.videoInfo !== null) {
                return this.videoInfo.title;
            }
            return "Title";
        },
        /**
         * Post message is how we can run functions that pause/play our embeds
         */
        isPostMessageSupported() {
            return window.postMessage;
        },
    },
    async mounted() {
        await this.initVideo();
    },
    methods: {
        async initVideo() {
            switch (this.type) {
                case "vimeo":
                case "youtube":
                    let embedUrl = this.type === "youtube" ?
                        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${this.videoId}&format=json` :
                        `https://vimeo.com/api/oembed.json?url=${this.src}`;
                    // Fetch Oembed
                    await window.fetch(embedUrl)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(response => {
                            this.videoInfo = response;
                            this.isVideoFound = true;
                        })
                        .catch(() => {
                            // handle error
                            this.videoInfo = null;
                            this.isVideoFound = false;
                        })
                        .finally(() => {
                            // this function is always executed
                            if (this.autoplay) {
                                this.initiateIframe();
                                this.playVideo();
                            }
                        });
                    break;
                case "hosted":
                    this.isVideoFound = true;

                    if (this.autoplay) {
                        this.initiateIframe();
                        this.playVideo();
                    }
                    break;
            }
        },
        /**
         * When video is clicked, play the video
         */
        handleClick() {
            if (this.clicked) {
                return;
            }
            this.clicked = true;
            if (this.iframeEl === null) {
                this.initiateIframe(true);
            }
            if (this.modal) {
                this.$refs.videoModal.classList.add('show');
            } else {
                this.$refs.videoWrapper.remove();
            }
            this.playVideo();
        },
        closeModal() {
            this.clicked = false;
            this.stopVideo();
            this.$refs.videoModal.classList.remove('show');
        },
        pauseVideo() {
            if (this.iframeEl === null) {
                return;
            }
            if (this.type === "youtube") {
                if (this.isPostMessageSupported) {
                    this.iframeEl.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                }
            } else {
                this.iframeEl.pause();
            }
        },
        playVideo() {
            if (this.iframeEl === null) {
                this.initiateIframe(true);
            }
            if (this.type === "youtube") {
                if (this.isPostMessageSupported) {
                    this.iframeEl.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                }
            } else {
                this.iframeEl.play();
            }
        },
        stopVideo() {
            if (this.iframeEl === null) {
                return;
            }
            if (this.type === "youtube") {
                if (this.isPostMessageSupported) {
                    this.iframeEl.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
                }
            } else {
                this.iframeEl.pause();
                this.iframeEl.currentTime = 0;
            }
        },
        /**
         * Generate the iframe
         * If autoplay, then generate on page load
         * If it's not on autoplay, then generate when the user clicks on the video.
         * The video will be lazy loaded (just an image) until you click, at which point it generates an iframe
         * and attaches it to the video wrapper on the DOM.
         */
        initiateIframe(autoplay = this.autoplay) {
            let iframeEl, src, allow;

            switch (this.type) {
                case "youtube":
                    src = `https://www.youtube.com/embed/${this.videoId}?enablejsapi=1&rel=0`;
                    allow = ``;

                    if (autoplay) {
                        src = src + `&autoplay=1`;
                        allow = allow + ` autoplay;`;
                    }
                    if (this.mute) {
                        src = src + `&mute=1`;
                    }
                    if (this.loop) {
                        src = src + `&loop=1&playlist=${this.videoId}`;
                    }
                    if (!this.controls) {
                        src = src + `&controls=0&modestbranding=1&showinfo=0&autohide=1`;
                    }

                    iframeEl = document.createElement('iframe');
                    iframeEl.setAttribute('src', `${src}`);
                    iframeEl.setAttribute('frameborder', '0');
                    iframeEl.setAttribute('allow', `accelerometer; encrypted-media; gyroscope; picture-in-picture;${allow}`);
                    iframeEl.setAttribute('allowfullscreen', '1');
                    iframeEl.setAttribute('title', `${this.title}`);
                    iframeEl.setAttribute('class', `${this.iframeClass}`);
                    break;
                case "vimeo":
                    src = `https://player.vimeo.com/video/${this.videoId}?autopause=0`;

                    if (autoplay) {
                        src = src + `&autoplay=1`;
                    }
                    if (this.mute) {
                        src = src + `&muted=1`;
                    }
                    if (this.loop) {
                        src = src + `&loop=1`;
                    }

                    iframeEl = document.createElement('iframe');
                    iframeEl.setAttribute('src', `${src}`);
                    iframeEl.setAttribute('frameborder', '0');
                    if (this.mute) {
                        iframeEl.setAttribute('muted', '');
                    }
                    iframeEl.setAttribute('allow', `accelerometer; encrypted-media; gyroscope; picture-in-picture;`);
                    iframeEl.setAttribute('allowfullscreen', '1');
                    iframeEl.setAttribute('title', `${this.title}`);
                    iframeEl.setAttribute('class', `${this.iframeClass}`);
                    break;
                case "hosted":
                    iframeEl = document.createElement('video');
                    iframeEl.setAttribute('width', `100%`);
                    iframeEl.setAttribute('height', `100%`);
                    iframeEl.setAttribute('class', `${this.iframeClass}`);

                    if (autoplay) {
                        iframeEl.setAttribute('autoplay', '');
                    }
                    if (this.controls) {
                        iframeEl.setAttribute('controls', '');
                    }
                    if (this.loop) {
                        iframeEl.setAttribute('loop', '');
                    }
                    if (this.mute) {
                        iframeEl.setAttribute('muted', '');
                        iframeEl.volume = 0;
                    }

                    let sourceEl = document.createElement('source');
                    sourceEl.setAttribute('src', `${this.src}`);
                    sourceEl.setAttribute('type', `video/mp4`);
                    iframeEl.appendChild(sourceEl);
                    break;
            }
            this.iframeEl = iframeEl;

            // Get the video-wrap DOM element and append our iframe to it
            this.$refs.videoWrap.appendChild(this.iframeEl);
            this.iframeEl.focus();
        },
    },
}
</script>
