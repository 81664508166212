<template>
    <div class="video-error-container">
        <span class="video-error-icon">
            <svg fill="#fff" viewBox="0 0 48 48">
                <path d="M0 0h48v48H0V0z" fill="none"></path>
                <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" fill-opacity="0.7"></path>
              </svg>
        </span>
        <span class="video-error-content">
            <span class="video-error-content__reason">
                <span>Video unavailable</span>
            </span>
            <span class="video-error-content__subreason">
                <span>This video is unavailable.</span>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: "VideoError",
}
</script>
