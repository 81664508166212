import AllVideosList from "./AllVideosList";
import VideoPlayer from "./video/VideoPlayer.vue";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'video-player': VideoPlayer,
    'all-videos-list': AllVideosList,
};
