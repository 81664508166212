<template>
    <div v-if="isTeaserThumbnailExists" class="vue-instance video-teaser-thumbnail">
        <tm-video
            :autoplay="true"
            :controls="false"
            :loop="true"
            :max-width="maxWidth"
            :mute="true"
            :show-preview="false"
            :src="teaserThumbnail"
            :thumbnail-quality="thumbnailQuality"
            :type="teaserType"
        >
        </tm-video>
    </div>
    <img v-else-if="isCustomThumbnailExist" :alt="altText" :src="customThumbnail" @error="$event.target.src=thumbnail">
    <img v-else :alt="altText" :src="thumbnail">

    <template v-if="showTitle">
        <span class="video-title" v-text="title"></span>
    </template>
</template>

<script>
export default {
    name: "VideoThumbnail",
    /**
     * All are required because they are passed in from the VideoPlayer.
     * The VideoPlayer will either pass in IT'S default value or the value passed into it.
     * This means that we will only ever receive a value. It will never be null.
     */
    props: {
        /**
         ******************** Required *******************
         */

        customThumbnail: {
            type: String,
            required: true,
        },
        maxWidth: {
            type: String,
            required: true,
        },
        showTitle: {
            type: Boolean,
            required: true,
        },
        teaserThumbnail: {
            type: String,
            required: true,
        },
        teaserType: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        thumbnailQuality: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        videoId: {
            type: String,
            required: true,
        },
    },
    computed: {
        isCustomThumbnailExist() {
            return this.customThumbnail.trim().length > 0;
        },
        isTeaserThumbnailExists() {
            return this.teaserThumbnail.trim().length > 0;
        },
        altText() {
            return 'Video Thumbnail | ' + this.title;
        },
        thumbnail() {
            let quality;
            switch (this.type) {
                case "youtube":
                    switch (this.thumbnailQuality) {
                        case 'low':
                            quality = 'default';
                            break;
                        case 'medium':
                            quality = 'mqdefault';
                            break;
                        case 'high':
                            quality = 'hqdefault';
                            break;
                        case 'maxres':
                            quality = 'maxresdefault';
                            break;
                        default:
                            // Default is standard
                            // 'sddefault' ONLY WORKS SOMTIMES!!
                            quality = '0';
                            break;
                    }

                    return 'https://img.youtube.com/vi/' + this.videoId + '/' + quality + '.jpg';
                case "vimeo":
                    switch (this.thumbnailQuality) {
                        case 'low':
                            quality = '200x150';
                            break;
                        case 'medium':
                            quality = '295x166';
                            break;
                        case 'high':
                            quality = '640x360';
                            break;
                        case 'maxres':
                            quality = '1280x720';
                            break;
                        default:
                            // Default is standard
                            quality = '960x540';
                            break;
                    }

                    return 'https://i.vimeocdn.com/video/' + this.videoId + '_' + quality + '.jpg';
            }
        },
    },
}
</script>
